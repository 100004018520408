import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Select from "react-select";
import { Tabs } from "react-simple-tabs-component";
import { CourseListAdmin } from "src/components";
import {
  AdminUser,
  ICourse,
  PriceListOption,
  StudentStatus,
  update_user,
  packageOptions,
} from "src/interface";
import { authHeader } from "src/utils/authHeader";
import "./AdminEditUser.scss";
import { ModalUserUpdate } from "src/components";

export const AdminEditUser = () => {
  document.title = "Редактирования пользователя | EasyENT";
  const params = useParams();
  const [user, setUser] = useState<AdminUser>();
  const addUserFeeds = (newFeed: any) => {
    if(user){
      const addUserFeeds = [...user.user_feeds, newFeed];
      setUser({ ...user, user_feeds: addUserFeeds });
    }
  };

  const updateUserFeeds = (updatedFeed: any) => {
    if (user) {
      const updatedFeeds = user.user_feeds.map((feed) =>
        feed.course_id === updatedFeed.course_id 
        &&  feed.start_date === updatedFeed.start_date   
      ? updatedFeed : feed
      );
      setUser({ ...user, user_feeds: updatedFeeds });
    }
  };
  
  const [coursePackage, setCoursePackage] = useState<packageOptions>({
    value: "standard", label: "Про" 
  });
  const [price, setPrice] = useState<update_user>({
    course_price: 0,
    status_student: null,
    pricelist: 0,
  });
  const [courseLang, setCourseLang] = useState<string>("");
  const [statusValue, setStatusValue] = useState<StudentStatus>({
    value: null,
    label: "",
  });
  const [priceListValue, setPriceListValue] = useState<PriceListOption>({
    value: 0,
    label: "Прайс листа нету",
  });
  const [modalActive, setModalActive] = useState(false); 
  const options: StudentStatus[] = [
    { value: true, label: "Ученик" },
    { value: null, label: "Пользователь" },
    { value: false, label: "Отказался от курсов" },
  ];
  const packageOptions: packageOptions[] = [
    { value: "standard", label: "Про" },
    { value: "premium", label: "Премиум" },
  ];
  const priceListoptions: PriceListOption[] = [
    { value: 1, label: "Про 25%" },
    { value: 2, label: "Премиум 25%" },
    { value: 3, label: "Про 20%" },
    { value: 4, label: "Премиум 20%" },
    { value: 5, label: "Про 19%" },
    { value: 6, label: "Премиум 19%" },
    { value: 7, label: "Годовой 1+1" },
    { value: 8, label: "Годовой 2+2" },
    { value: 9, label: "Годовой" },
    { value: 10, label: "Тестовый 2 недели" },
    { value: 11, label: "Про 30%" },
    { value: 12, label: "Премиум 30%" },
    { value: 13, label: "Про 40%" },
    { value: 14, label: "Премиум 40%" },
  ];
  const [coursesList, setCoursesList] = useState<ICourse[]>([]);

 

  useEffect(() => {
    axios
      .get("/api/v1/users/" + params.id + "/", { headers: authHeader() })
      .then(
        (response) => (
          setUser(response.data),
          console.log(response.data),
          setCourseLang(response.data.lang),
          setPrice({
            course_price: response.data.course_price,
            pricelist: response.data.pricelist,
            status_student: response.data.stutus_student,
          })
        )
      );
  }, []);

  useEffect(() => {
    if (courseLang !== "") {
      axios
        .get("/api/v1/courses?lang=" + courseLang + "&active=true", { headers: authHeader() })
        .then((response) => setCoursesList(response.data));
    }
  }, [courseLang]);

  useEffect(() => {
    axios
      .get("/api/v1/users/" + params.id + "/", { headers: authHeader() })
      .then((response) => setUser(response.data));
  }, []);

  useEffect(() => {
    if (user) {
      if (user.status_student == true) {
        setStatusValue({ value: true, label: "Ученик" });
      } else if (user.status_student == false) {
        setStatusValue({ value: false, label: "Отказался от курсов" });
      } else {
        setStatusValue({ value: null, label: "Пользователь" });
      }
      if (user.pricelist && user.pricelist !== null) {
        if (user.pricelist == 1) {
          setPriceListValue({
            value: 1,
            label: "Про 25%",
          });
        } else if (user.pricelist == 2) {
          setPriceListValue({
            value: 2,
            label: "Премиум 25%",
          });
        } else if (user.pricelist == 3) {
          setPriceListValue({
            value: 3,
            label: "Про 20%",
          });
        } else if (user.pricelist == 4) {
          setPriceListValue({
            value: 4,
            label: "Премиум 20%",
          });
        } else if (user.pricelist == 5) {
          setPriceListValue({
            value: 5,
            label: "Про 19%",
          });
        } else if (user.pricelist == 6) {
          setPriceListValue({
            value: 6,
            label: "Премиум 19%",
          });
        } else if (user.pricelist == 7) {
          setPriceListValue({
            value: 7,
            label: "Годовой 1+1",
          });
        } else if (user.pricelist == 8) {
          setPriceListValue({
            value: 8,
            label: "Годовой 2+2",
          });
        } else if (user.pricelist == 9) {
          setPriceListValue({
            value: 9,
            label: "Годовой",
          });
        }
        else if (user.pricelist == 10) {
          setPriceListValue({
            value: 10,
            label: "Тестовый",
          });
        }
        else if (user.pricelist == 11) {
          setPriceListValue({
            value: 11,
            label: "Про 30%",
          });
        }
        else if (user.pricelist == 12) {
          setPriceListValue({
            value: 12,
            label: "Премиум 30%",
          });
        }
        else if (user.pricelist == 13) {
          setPriceListValue({
            value: 13,
            label: "Про 40%",
          });
        }
        else if (user.pricelist == 14) {
          setPriceListValue({
            value: 14,
            label: "Премиум 40%",
          });
        }
      }
    }
  }, [user]);

  const onChangePrice = (e: React.ChangeEvent<HTMLInputElement>) => {
    const element = e.target as HTMLInputElement;
    const value = parseInt(element.value);
    setPrice((oldprice) => ({ ...oldprice, course_price: value }));
  };

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (price.course_price > 0) {
      if (user && user.email) {
        axios
          .put("/api/v1/users/" + user.email, price, { headers: authHeader() })
          .then((response) => {
            console.log(response.data);
            if (response.status == 200) {
              console.log(response);
            }
          })
          .catch((error) => {
            alert(error.response.data);
          });
      }
    } else {
      alert("пусто");
    }
    window.location.reload();
  };
  const TabSeptember = () => {
    return (
      <>
        <div className="margin-bot">
          <Select
            value={coursePackage} options={packageOptions} onChange={(option: any) => {
              setCoursePackage(option);
            }}
          />
        </div>       
        {user && coursesList ? (
          <CourseListAdmin
            user={user} courseLang={courseLang} coursesList={coursesList} coursePackage={coursePackage.value}
            dateStart={"2024-09"}
            dateEnd={"2024-10"}
            addUserFeeds={addUserFeeds}
            updateUserFeeds={updateUserFeeds}
          />
        ) : (
          "Загрузка"
        )}
      </>
    );
  };
  const TabOctober = () => {
    return (
      <>
        <div className="margin-bot">
          <Select value={coursePackage} options={packageOptions} onChange={(option: any) => {
              setCoursePackage(option);}}
          />  
        </div>       
        {user && coursesList ? (
          <CourseListAdmin
            user={user} courseLang={courseLang} coursesList={coursesList} coursePackage={coursePackage.value}
            dateStart={"2024-10"} dateEnd={"2024-11"}
            addUserFeeds={addUserFeeds}
            updateUserFeeds={updateUserFeeds}
          />
        ) : (
          "Загрузка"
        )}
      </>
    );
  };
  const TabNovember = () => {
    return (
      <>
        <div className="margin-bot">
          <Select value={coursePackage} options={packageOptions} onChange={(option: any) => {
              setCoursePackage(option);}}
          />  
        </div>       
        {user && coursesList ? (
          <CourseListAdmin
            user={user} courseLang={courseLang} coursesList={coursesList} coursePackage={coursePackage.value}
            dateStart={"2024-11"} dateEnd={"2024-12"}
            addUserFeeds={addUserFeeds}
            updateUserFeeds={updateUserFeeds}
          />
        ) : (
          "Загрузка"
        )}
      </>
    );
  };
  const TabDecember = () => {
    return (
      <>
        <div className="margin-bot">
          <Select value={coursePackage} options={packageOptions} onChange={(option: any) => {
              setCoursePackage(option);}}
          />  
        </div>       
        {user && coursesList ? (
          <CourseListAdmin
            user={user} courseLang={courseLang} coursesList={coursesList} coursePackage={coursePackage.value}
            dateStart={"2024-12"} dateEnd={"2025-01"}
            addUserFeeds={addUserFeeds}
            updateUserFeeds={updateUserFeeds}
          />
        ) : (
          "Загрузка"
        )}
      </>
    );
  };
  const TabJanuary = () => {
    return (
      <>
        <div className="margin-bot">
          <Select value={coursePackage} options={packageOptions} onChange={(option: any) => {
              setCoursePackage(option);}}
          />  
        </div>       
        {user && coursesList ? (
          <CourseListAdmin
            user={user} courseLang={courseLang} coursesList={coursesList} coursePackage={coursePackage.value}
            dateStart={"2025-01"} dateEnd={"2025-02"}
            addUserFeeds={addUserFeeds}
            updateUserFeeds={updateUserFeeds}
          />
        ) : (
          "Загрузка"
        )}
      </>
    );
  };
  const TabFebruary = () => {
    return (
      <>
        <div className="margin-bot">
          <Select value={coursePackage} options={packageOptions} onChange={(option: any) => {
              setCoursePackage(option);}}/>
        </div>       
        {user && coursesList ? (
          <CourseListAdmin
            user={user} courseLang={courseLang} coursesList={coursesList} coursePackage={coursePackage.value}
            dateStart={"2025-02"} dateEnd={"2025-03"}
            addUserFeeds={addUserFeeds}
            updateUserFeeds={updateUserFeeds}
          />
        ) : (
          "Загрузка"
        )}
      </>
    );
  };
  const TabMarch = () => {
    return (
      <>
        <div className="margin-bot">
          <Select value={coursePackage} options={packageOptions} onChange={(option: any) => {
          setCoursePackage(option);}}/> 
        </div>       
        {user && coursesList ? (
          <CourseListAdmin
            user={user} courseLang={courseLang} coursesList={coursesList} coursePackage={coursePackage.value}
            dateStart={"2025-03"} dateEnd={"2025-04"}
            addUserFeeds={addUserFeeds}
            updateUserFeeds={updateUserFeeds}
          />
        ) : (
          "Загрузка"
        )}
      </>
    );
  };
  const TabApril = () => {
    return (
      <>
        <div className="margin-bot">
          <Select value={coursePackage} options={packageOptions} onChange={(option: any) => {
          setCoursePackage(option);}}/>
        </div>       
        {user && coursesList ? (
          <CourseListAdmin
            user={user} courseLang={courseLang} coursesList={coursesList} coursePackage={coursePackage.value}
            dateStart={"2025-04"} dateEnd={"2025-05"}
            addUserFeeds={addUserFeeds}
            updateUserFeeds={updateUserFeeds}
          />
        ) : (
          "Загрузка"
        )}
      </>
    );
  };
  const Standtabs = [
    {
      label: "Сентябрь",
      Component: TabSeptember,
    },
    {
      label: "Октябрь",
      Component: TabOctober,
    },
    {
      label: "Ноябрь",
      Component: TabNovember,
    },
    {
      label: "Декабрь",
      Component: TabDecember,
    },
    {
      label: "Январь",
      Component: TabJanuary,
    },
    {
      label: "Февраль",
      Component: TabFebruary,
    },
    {
      label: "Март",
      Component: TabMarch,
    },
    {
      label: "Апрель",
      Component: TabApril,
    },
  ];
  return (
    <div>
      <h1 className="title">Редактирование пользователя</h1>
      {user && courseLang ? (
        <div>
          <div className="box admin-user-top">
            <ul className="admin-user-ul">
              <li>
                <span>ФИО:</span> {user.full_name}
              </li>
              <li>
                <span>Номер:</span> {user.phone}
              </li>
              <li>
                <span>Email:</span> {user.email}
              </li>
              <li>
                <span>Телеграм:</span> {user.zoom_account}
              </li>
              <li>
                <span>Язык обучения:</span>{" "}
                {user.lang == "kz" ? "Казахский" : "Русский"}
              </li>
            </ul>
            <div className="flex">
              <Link
                to={`/admin/users/perfomance/${user.id}`}
                className="btn btn--margin btn-primary btn-inline"
              >
                Успеваемость ученика
              </Link>
              <div onClick={() => setModalActive(true)}  className="btn btn-primary btn-inline">
                Обновить пользователя
              </div>
            </div>
          </div>
          <form onSubmit={onSubmit} className="form-user-update box">
            <div className="form-update_part">
              <div className="form-update-row">
                <label className="form-update-row__label">
                  Cтатус ученика
                </label>
                <Select
                  value={statusValue}
                  options={options}
                  onChange={(option: any) => {
                    setPrice((oldprice) => ({
                      ...oldprice,
                      status_student: option.value,
                    }));
                    setStatusValue(option);
                  }}
                />
              </div>
              <div className="form-update-row">
                <label className="form-update-row__label">Пакет</label>
                <Select
                  value={priceListValue}
                  options={priceListoptions}
                  onChange={(option: any) => {
                    setPrice((oldprice) => ({
                      ...oldprice,
                      pricelist: option.value,
                    }));
                    setPriceListValue(option);
                  }}
                  styles={{
                    container: (provided) => ({
                      ...provided,
                      minWidth: '170px',
                    }),
                  }}
                />
              </div>
              <div className="form-update-row">
                <label className="form-update-row__label">Оплата</label>
                <input
                  className="form-update-row__input"
                  onChange={onChangePrice}
                  value={price.course_price !== null ? price.course_price : ""} 
                  type="text"
                ></input>
              </div>
            </div>
            <button className="btn btn-primary">Обновить</button>
          </form>
          {/* {statusValue && priceListValue && (
            <AddCourse user={user} coursesList={coursesList} />
          )} */}
          <ModalUserUpdate
            active={modalActive}
            setActive={setModalActive}
            user={user}
          />
          <Tabs tabs={Standtabs} />
        </div>
      ) : (
        <p>Загрузка</p>
      )}
    </div>
  );
};
