import axios from "axios";
import React, { useEffect, useState } from "react";
import { newOrder, orderStatus } from "src/interface";
import { Link } from "react-router-dom";
import Select from 'react-select';
import "./AdminOrderPage.scss";
import { authHeader } from "src/utils/authHeader";

export const AdminOrdersPage = () => {
  document.title = "Ордера | EasyENT";
  const [allorders, setAllorders] = useState<newOrder[]>([]);
  const [orderStatus, setOrderStatus] = useState<orderStatus>({
    value:"draft", label: 'На оплату'
  });
  const [dateOption, setDateOption] = useState<any>({
    value:"2024-04-11", label: '1 поток'
  });
  const orderoptions:orderStatus[] = [
    { value: 'all', label: 'Все' },
    { value: 'draft', label: 'На оплату' },
    { value: 'payed', label: 'Оплачено' },
    { value: 'canceled', label: 'Отменено' }
  ];
  const dateoptions:orderStatus[] = [
    { value: '2024-04-11', label: '1 поток' },
  ];
  useEffect(() => {
      axios
      .get("https://app.easyent.kz/api/v1/order/?skip=0&limit=500&status=" + orderStatus.value + "&start_date=2024-10-09",{ headers: authHeader()})
      .then((response) => setAllorders(response.data));
  }, [orderStatus]);

  return (
    <div>
      <h1 className="title">Платежки</h1>
      <div className="select-part">
        <Link to="payment" className="btn btn-primary btn-inline">Выставить платежку</Link>
        <div className="select-part__item">
          <p>Поток</p>
          <Select
              value={dateOption}
              options={dateoptions}
              onChange={(option: any) => {
               if( option) setDateOption(option)
                
                  }
                } 
          />
        </div>
        <div className="select-part__item">
          <p>Вид оплаты</p>
          <Select
              value={orderStatus}
              options={orderoptions}
              onChange={(option: any) => {
                if (option) setOrderStatus(option)
              }} 
            />    
        </div>
      </div>
      <table className="table table-fontsize">
        <tbody>
        <tr>
          <th style={{width: '40px'}}>
            Id
          </th>
          <th style={{width: '45px'}}>
            uid
          </th>
          <th style={{width: '230px'}}>
            Фио
          </th>
          <th style={{width: '200px'}}>
            Номер
          </th>          
          <th style={{width: '200px'}}>
            Почта
          </th>
          <th style={{width: '110px'}}>
            Cтатус
          </th>
          <th style={{width: '110px'}}>
            Дата
          </th>
          <th style={{width: '80px'}}>
            Сумма
          </th>
          <th style={{width: '130px'}}>
            Управление
          </th>
        </tr>
        {allorders.length ?  allorders.map((order) => (
          <tr key={order.id}>
            <td style={{width: '40px'}}>
              {order.id}
            </td>
            <td style={{width: '45px'}}>
              {order.user.id}
            </td>
            <td style={{width: '230px'}}>
              {order.user.full_name}
            </td>
            <td style={{width: '230px'}}>
              {order.user.phone}
            </td>
            <td style={{width: '220px'}}>
              {order.user.email}
            </td>
            <td style={{width: '110px'}}>
              {order.status == "draft" ? "на оплату" : "оплачено"}
            </td>
            <td style={{width: '110px'}}>
              {order.start_date} 
            </td>
            <td style={{width: '90px'}}>
              {order.total_amount} 
            </td>
            <td style={{width: '130px'}}>
              <div className="item-control">
                <Link to={`/admin/orders/edit/${order.id}`} className="item-control__item">
                  <i className="fa-solid fa-pen"></i> Редактировать
                </Link>
              </div>
            </td>
          </tr>
        )) : "Загрузка"}
        </tbody>
      </table>
    </div>
  );
};
