import axios from "axios";
import React, { FC, useState } from "react";
import { authHeader } from "src/utils/authHeader";
import "./ModalUserUpdate.scss";

export const ModalUserUpdate: FC<{active:boolean,setActive:(value:boolean) => void, user:any}> = 
  ({active, setActive,user}) => {
  console.log(user);
  const [me, setMe] = useState({
    full_name: user.full_name,
    lang: user.lang,
    phone: user.phone,
    zoom_account: user.zoom_account
  });
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const element = e.target as HTMLInputElement;
    const value = element.value;
    const name = element.name;
    setMe((s) => ({ ...s, [name]: value }));
  };
  
  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    axios
    .put('/api/v1/users/' + user.email, me, { headers: authHeader()})
      .then((response) => {
        console.log(response.data);
        if(response.status == 200) {            
          window.location.reload();
        }
      })
      .catch(error => {
        alert(error.response.data);
        window.location.reload();
      });
  };
  return (
    <div className={active ? "modal-photo active" : "modal-photo"} onClick={()=> setActive(false)}>
      <div className="modal-content" onClick={e => e.stopPropagation()}>
        <h1 className="modal-content__heading">Обновить пользователя</h1>
        <form onSubmit={onSubmit} className="popup-form">
          <div className="edit-form-row">
            <label>ФИО:</label>
            <input
              className="input"
              placeholder="ФИО"
              readOnly
              name="full_name"
              type="text"
              onChange={onChange}
              defaultValue={user.full_name}
              onInput={(e) => {
                const element = e.target as HTMLInputElement;
                if (/[a-zA-Z]/.test(element.value)) {
                  element?.setCustomValidity("Введите символы на кириллице");
                } else {
                  element?.setCustomValidity("")
                }
              }}
            />
          </div>
          <div className="edit-form-row">
            <label>Язык:</label>
            <input
              className="input"
              placeholder="Язык"
              name="lang"
              onChange={onChange}
              required
              type="text"
              defaultValue={user.lang}
            />
          </div>
          <div className="edit-form-row">
            <label>Телефон:</label>  
            <input
              className="input"
              placeholder="Номер телефона"
              required
              type="text"
              onChange={onChange}
              name="phone"
              defaultValue={user.phone}
            />
          </div>
          {/* <div className="edit-form-row">  
            <label>Аккаунт в Телеграме:</label>  
            <input
              className="input"
              placeholder="Логин в телеге"
              name="zoom_account"
              type="text"
              onChange={onChange}
              defaultValue={user.zoom_account}
          />
          </div> */}
          <div className="btn-group">
            <button className="btn btn-primary" type="submit">
              Обновить <i className="fa-solid fa-floppy-disk"></i>
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}
