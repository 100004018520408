import axios from "axios";
import React, { FC, useEffect, useState } from "react";
import { AdminUser, ICourse, User_feeds } from "src/interface";
import { authHeader } from "src/utils/authHeader";
import "./CourseListAdmin.scss";
export const CourseListAdmin: FC<{
  courseLang: string;
  coursesList: ICourse[];
  dateStart: string;
  dateEnd: string;
  user: AdminUser;
  coursePackage: string;
  addUserFeeds: (newFeed: User_feeds) => void;
  updateUserFeeds: (updatedFeed: User_feeds) => void; 
}> = ({ dateStart, dateEnd, user, coursesList, coursePackage, addUserFeeds, updateUserFeeds }) => {
  const [userFeeds, setUserFeeds] = useState<User_feeds[]>([]);
  const [sendUserFeed, setSendUserFeed] = useState<boolean>(true);
  useEffect(() => {
    if (user && user.user_feeds) {
      setUserFeeds(user.user_feeds);
    }
  }, []);
  const addUserFeed = (e: React.MouseEvent<Element>) => {
    let start_date = "",
      end_date = "";
    const element = e.currentTarget;
    if (element.getAttribute("data-potok") == "10") {
      start_date = dateStart + "-09";
      end_date = dateEnd + "-08";
    } else {
      start_date = dateStart + "-10";
      end_date = dateEnd + "-10";
    }
    if (start_date && end_date) {
      const newobj = {
        user_id: parseInt("" + element.getAttribute("data-user-id")),
        course_id: parseInt("" + element.getAttribute("data-course-id")),
        start_date: start_date,
        package: coursePackage,
        end_date: end_date,
        status: "active",
      };
      axios
        .post("/api/v1/user_feed/", newobj, { headers: authHeader() })
        .then((response) => {
          if (response.status == 200) {
            console.log(response);
            setUserFeeds((prevState) => [...prevState, response.data]);
            addUserFeeds(response.data);
            setSendUserFeed(true);
            console.log(userFeeds);
          }
        })
        .catch((error) => {
          alert(error.response.data);
        });
    }
  };

  const removeUserFeed = (e: React.MouseEvent<Element>) => {
    console.log(userFeeds)
    setSendUserFeed(false);
    const element = e.currentTarget;
    console.log(element.getAttribute("data-course-id"));
    if (element.getAttribute("data-course-id") !== null) {
      let start_date = "";
      if (element.getAttribute("data-potok") == "10") {
        start_date = dateStart + "-09";
      } else if (element.getAttribute("data-potok") == "4") {
        start_date = dateStart + "-08";
      }
      const obj: any = userFeeds.find(
        (o) =>
          o.start_date === start_date &&
          String(o.course_id) === element.getAttribute("data-course-id")
      );
      console.log(obj)
      obj.status = "inactive";
      if (obj) {
        axios
          .put("/api/v1/user_feed/" + obj.id + "/", obj, {
            headers: authHeader(),
          })
          .then((response) => {
            if (response.status == 200) {
              setUserFeeds((prevState) => [...prevState, obj]);
              updateUserFeeds(obj);
              setSendUserFeed(true);
            }
          })
          .catch((error) => {
            alert(error.response.data);
          });
      }
    }
  };
  const updateUserFeed = (e: React.MouseEvent<Element>) => {
    setSendUserFeed(false);
    const element = e.currentTarget;
    if (element.getAttribute("data-course-id") !== null) {
      let start_date = "";
      if (element.getAttribute("data-potok") == "10") {
        start_date = dateStart + "-09";
      } 
      const obj: any = userFeeds.find(
        (o) =>
          o.start_date === start_date &&
          String(o.course_id) === element.getAttribute("data-course-id")
      );
      obj.status = "active";
      axios
        .put("/api/v1/user_feed/" + obj.id + "/", obj, {
          headers: authHeader(),
        })
        .then((response) => {
          if (response.status == 200) {
            setUserFeeds((prevState) => [...prevState, obj]);
            updateUserFeeds(obj);
            setSendUserFeed(true);
          }
        })
        .catch((error) => {
          alert(error.response.data);
        });
    }
  };
  const changeUserFeedsPackage = (e: React.MouseEvent<Element>, packageType: string) => {
    const element = e.currentTarget;
    if (element.getAttribute("data-course-id") !== null) {
      let start_date = "";
      if (element.getAttribute("data-potok") == "10") {
        start_date = dateStart + "-09";
      } 
      const obj: any = userFeeds.find(
        (o) =>
          o.start_date === start_date &&
          String(o.course_id) === element.getAttribute("data-course-id")
      );
      obj.package = packageType;
      axios
        .put("/api/v1/user_feed/" + obj.id + "/", obj, {
          headers: authHeader(),
        })
        .then((response) => {
          if (response.status == 200) {
            setUserFeeds((prevState) => [...prevState, obj]);
            updateUserFeeds(obj);
            setSendUserFeed(true);
          }
        })
        .catch((error) => {
          alert(error.response.data);
        });
    }
  };
  
  return (
    <>
      <div>
        <div className="addcourse-ul">
          {coursesList.map((course) => (
            <div key={course.id + "_10"}>
              {course.active ? (
                <div className="addcourse-list">
                  <div className="add-inline add-inline--first">
                    {/* Check if user feed exists for the course and the start date */}
                    {userFeeds.some(
                      (e) => e.course_id === course.id && e.start_date.slice(0, -3) == dateStart
                    ) ? (
                      // Check if the feed is active
                      userFeeds.some(
                        (e) =>
                          e.course_id === course.id &&
                          e.start_date.slice(0, -3) == dateStart &&
                          e.status === "active"
                      ) ? (
                        // Active feed UI
                        <div
                          onClick={sendUserFeed ? removeUserFeed : () => console.log("Feed update in progress")}
                          data-start={dateStart}
                          data-course-id={course.id}
                          data-potok={course.price}
                          className="tipo-input"
                        >
                          <i className="fa-solid fa-check"></i>
                        </div>
                      ) : (
                        // Inactive feed UI (update feed)
                        <div
                          onClick={sendUserFeed ? updateUserFeed : () => console.log("Feed update in progress")}
                          data-start={dateStart}
                          data-course-id={course.id}
                          data-potok={course.price}
                          className="tipo-input"
                        ></div>
                      )
                    ) : (
                      // Add a new feed
                      <>
                        {sendUserFeed ? (
                          <div
                            onClick={(event) => {
                              setSendUserFeed(false);
                              addUserFeed(event);
                            }}
                            data-user-id={user ? user.id : ""}
                            data-start={dateStart}
                            data-end={dateEnd}
                            data-potok={course.price}
                            data-course-id={course.id}
                            className="tipo-input"
                          ></div>
                        ) : (
                          <div className="tipo-input"></div>
                        )}
                      </>
                    )}
                    <p>{course.name}</p>
                  </div>
              
                {userFeeds.some(
                  (e) => e.course_id === course.id && e.start_date.slice(0, -3) == dateStart && e.status === "active"
                ) && (
                  <div className="flex">
                    <div className="add-inline">
                      {/* Check if the package is "standard" */}
                      <div
                        onClick={(event) => changeUserFeedsPackage(event, "standard")}
                        data-start={dateStart}
                        data-course-id={course.id}
                        data-potok={course.price}
                        className="tipo-input"
                      >
                        {userFeeds.find(
                          (feed) => feed.course_id === course.id && feed.start_date.slice(0, -3) == dateStart && feed.package === "standard"
                        ) && <i className="fa-solid fa-check"></i>}
                      </div>
                      <p>Про</p>
                    </div>
                    <div className="add-inline">
                      {/* Check if the package is "premium" */}
                      <div
                        onClick={(event) => changeUserFeedsPackage(event, "premium")}
                        data-start={dateStart}
                        data-course-id={course.id}
                        data-potok={course.price}
                        className="tipo-input"
                      >
                        {userFeeds.find(
                          (feed) => feed.course_id === course.id && feed.start_date.slice(0, -3) == dateStart &&  feed.package === "premium"
                        ) && <i className="fa-solid fa-check"></i>}
                      </div>
                      <p>Премиум</p>
                    </div>
                  </div>
                )}
              </div>
               
              ) : (
                ""
              )}
            </div>
          ))}
        </div>
      </div>
    </>
  );
};
